import React from 'react'
import styled from 'styled-components'
import Layout from '../components/Layout'
import Button from '../components/shared/Button/Button'
import MainGrid from '../components/styling/MainGrid'
import img from '../images/computer_greendisc.svg'

const NotFoundPage = () => (
  <Layout title="R&D Page Not Found" description="R&D Page Not Found">
    <Outer>
      <MainGrid>
        <Inner>
          <ImgWrapper>
            <Img src={img} alt="Woman and man at a desk" />
          </ImgWrapper>
          <Line />
          <Text>
            <h1>Oops! The page you were looking for doesn’t exist.</h1>
            <h6>
              The page requested couldn't be found: this could be a spelling
              error or a removed page.
            </h6>
            <Button href={process.env.BASE}>Go to homepage</Button>
          </Text>
        </Inner>
      </MainGrid>
    </Outer>
  </Layout>
)

export default NotFoundPage

const Outer = styled.section`
  border-top: 1px solid #f4f4f4;
  padding: 12px 0 50px;

  @media (min-width: 768px) {
    padding-bottom: 10rem;
  }
`

const Inner = styled.section`
  width: 100%;
  max-width: 950px;
  margin: 0 auto;
`
const ImgWrapper = styled.div`
  width: 100%;
  max-width: 290px;
  margin: 0 auto;
`

const Img = styled.img`
  width: 100%;
`
const Line = styled.div`
  width: 100%;
  max-width: 570px;
  margin: 0 auto;
  height: 2px;
  background: #000000;
`
const Text = styled.div`
  margin-top: 36px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  & h1 {
    margin-bottom: 26px;
  }

  & h6 {
    margin-bottom: 44px;
    font-weight: 300;
  }
`
